.header-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 8vh;
    background-color: #FFF;
    color: #FFF;
    padding: 0 20px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
}

.header-title{
    font-size: 1.5rem;
    font-weight: 700;
    color: #142a4c;
}