.visualizar-modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.visualizar-modal-body {
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
  background: #fff;
  border-radius: 5px;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  overflow-x: hidden;
}

.visualizar-modal-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.visualizar-modal-header h1 {
  margin-top: 10px;
  font-size: 2em;
  font-weight: 600;
  color: #142a4c;
  margin: 0;
}

.visualizar-modal-content {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.visualizar-modal-content-item {
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 auto;
  flex-direction: column;
}

.visualizar-modal-content-item h2 {
  font-size: 16px;
  font-weight: 600;
  color: #111;
  margin: 0;
}

.visualizar-modal-content-item p {
  font-size: 14px;
  color: #333;
  margin: 5px;
  word-wrap: break-word;
}

.visualizar-modal-footer {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.visualizar-modal-button {
  width: 25%;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background: #142a4c;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
  margin-top: 20px;
}

.visualizar-modal-button:hover {
  transform: scale(1.05);
}

.visualizar-tab-button {
  margin: 2vh 15px;
  border: none;
  color: #142a4c;
  font-size: 1.2rem;
  background-color: transparent;
}

.visualizar-tab-button:hover {
  color: #9ac31c;
  cursor: pointer;
}

.visualizar-modal-content-item-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
}

.edit-button-save {
  padding: 10px 10px;
  color: #fff;
  background: #142a4c;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
}

.edit-button-cancel {
  padding: 10px 10px;
  color: #fff;
  background: red;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
  transition: background 0.3s;
}

.edit-button-save:hover {
  background: #0e1d3b;
  transform: scale(1.05);
}

.edit-button-cancel:hover {
  background: #b30000;
  transform: scale(1.05);
}

.visualizar-edit-box {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 20px;
}

.visualizar-modal-content-item label {
  font-size: 14px;
  color: #333;
  margin-left: 5px;
  font-weight: 600;
}

.visualizar-modal-content-item>select {
  width: 56%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 12px;
  color: #333;
  margin-left: 5px;
  font-weight: 600;
}

.visualizar-modal-content-item>input,
.visualizar-modal-content-item>textarea {
  width: 50%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 12px;
  color: #333;
  margin-left: 5px;
  font-weight: 600;
}


.button-container {
  width: 100%;
}

.visualizar-modal-content-cadastro {
  display: grid;
  grid-template-columns: 1fr;
  width: 90%;
  margin: 0 auto;
}

.visualizar-modal-content-item-cadastro {
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.visualizar-modal-content-item-cadastro>input,
.visualizar-modal-content-item-cadastro>textarea {
  width: 40%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 12px;
  color: #333;
  margin-left: 5px;
}

.visualizar-modal-content-item-cadastro>select {
  width: 42%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 12px;
  color: #333;
  margin-left: 5px;
}

.visualizar-modal-content-item-cadastro h2 {
  text-align: justify;
  width: 30%;
  font-size: 16px;
  font-weight: 600;
  color: #111;
  margin: 0;
}

.visualizar-modal-content-item-cadastro p {
  font-size: 14px;
  color: #333;
  margin-left: 5px;
}

.content-itens {
  width: 47%;
  align-items: center;
  justify-content: flex-start;
}

.content-itens>h2 {
  width: 30%;
}

.content-itens>input {
  width: 70%;
}
.content-itens>select {
  width: 70%;
}
.content-itens>textarea {
  width: 80%;
  height: 10vh; 
  resize: none;
  overflow-y: auto;
  
}


@media screen and (max-width: 1400px) {
  .visualizar-modal-body {
    width: 60%;
    height: 80%;
    padding: 30px;
  }
}

@media screen and (min-width: 1400px) {
  .visualizar-modal-body {
    width: 60%;
    padding: 30px;
    height: 70%;
    overflow-x: hidden;
  }
}