.cadastro-software-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.softwares-box-forms {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.form-softwares {
  flex: 1 0 calc(40% - 16px);
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  margin: 0 auto;
}

.form-softwares label {
  width: 40%;
  font-size: 16px;
  font-weight: 600;
  color: #142a4c;
  margin-right: 5px;
}

.form-softwares input,
.form-softwares select {
  max-width: 300px;
  width: 60%;
  height: 30px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0 5px;
  font-size: 1rem;
  font-weight: 600;
  color: #142a4c;
  background-color: #fff;
  margin-bottom: 10px;
}

.form-softwares input:hover,
.form-softwares input:focus,
.form-softwares input:active,
.form-softwares select:hover,
.form-softwares select:focus,
.form-softwares select:active {
  border: 1px solid #9ac31c;
  cursor: pointer;
}

.next-button {
  width: 100%;
  margin: 20px 0;
  padding: 10px 10px;
  color: #fff;
  background: #142a4c;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
}

.services-container {
  display: flex;
  width: 60%;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  justify-content: center;
}

.service-card {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 200px;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}

.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border: 1px solid #9ac31c;
}

.service-checkbox {
  position: absolute;
  top: 10px;
  right: 10px;
}

.service-name {
  font-size: 16px;
  font-weight: 500;
  color: #142a4c;
}

.advance-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.advance-button:hover {
  background-color: #0056b3;
}
