.App {
  text-align: center;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "SourceCodePro-Regular";
  overflow: hidden;
}

@font-face {
  font-family: "SourceCodePro-Regular";
  src: url(../public/fonts/SourceSansPro-Regular.otf) format("opentype");
}

@font-face {
  font-family: "SourceCodePro-Bold";
  src: url(../public/fonts/SourceSansPro-Bold.otf) format("openType");
}

@font-face {
  font-family: "SourceCodePro-Black";
  src: url(../public/fonts/SourceSansPro-Black.otf) format("opentype");
}

/* Estilizar a barra de rolagem */
::-webkit-scrollbar {
  width: 5px; /* Largura da barra de rolagem */
}

/* Estilizar o indicador (o "ponteiro") da barra de rolagem */
::-webkit-scrollbar-thumb {
  background-color: #142a4c;
  border-radius: 5px;
}

/* Estilizar a barra de rolagem quando o cursor estiver sobre ela */
::-webkit-scrollbar-thumb:hover {
  background-color: #9ac31c;
  cursor: pointer;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

h1 {
  font-size: 2rem;
  margin: 0;
  padding: 0;
  color:#142a4c;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.number-container {
  width: 280px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.number-container > input {
  width: 150px;
  padding: 10px;
  border: 1.5px solid #142a4c; /* Define a cor e a espessura da borda */
  border-radius: 8px; /* Define os cantos arredondados */

}

.number-container > label {
  width: 50px;
  font-size: 1rem;
  text-align: left;
  color: #142a4c;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
