.container-modal-equipamentos {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
}

.container-modal-content-equipamentos {
  display: flex;
  width: 70%;
  height: 90%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-radius: 5px;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  overflow-x: hidden;

  padding: 20px;
  background: #fff;
}

.container-modal-header-equipamentos {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.container-modal-body-equipamentos {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.progress-container {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
  margin-top: 20px;
}

.progress-card {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.progress-card-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.progress-card-header h1 {
  font-size: 1rem;
  font-weight: 500;
  color: #142a4c;
}

.progress-bar {
  display: flex;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background: #f1f1f1;
}

.equipamento-table .progress-bar[aria-valuenow*="."] {
  background-color: #3490dc;
}

.equipamento-table-container {
  width: 100%;
  padding: 20px;
}

.equipamento-table-card {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.equipamento-table-card-body {
  padding: 20px;
}

.equipamento-table {
  width: 100%;
  border-collapse: collapse;
}

.equipamento-table th,
.equipamento-table td {
  padding: 12px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

.equipamento-table-item {
  color: #142a4c;
}

.equipamento-table-item b {
  font-weight: bold;
}

.progress {
  height: 10px;
  background-color: #f1f1f1;
  border-radius: 5px;
  overflow: hidden;
}

.red-bar {
  background-color: #e3342f;
}

.blue-bar {
  background-color: #3490dc;
}

.green-bar {
  background-color: #38c172;
}

.alter-modal-content-item {
  width: 90%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.alter-modal-content-item h2 {
  font-size: 16px;
  font-weight: 600;
  color: #111;
  margin: 0;
}

.alter-modal-content-item span {
  font-size: 14px;
  color: #333;
  margin: 5px;
  word-wrap: break-word;
}

.alter-modal-content-item input {
  width: 25%;
  padding: 10px;
  border: 1px solid #142a4c;
  border-radius: 4px;
  font-size: 12px;
  color: #333;
}

.alter-modal-content-item input:hover {
  border: 1px solid #142a4c;
}
