.relatorios-contander {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

.relatorios-header {
  width: 100%;
  max-height: 10%;
}

.relatorios-body {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 780px;
}

.relatorios-button {
  margin: 2vh 15px;
  border: none;
  color: #142a4c;
  font-size: 1rem;
  font-weight: 600;
  background-color: transparent;
}

.relatorios-button:hover {
  color: #9ac31c;
  cursor: pointer;
}
.open-table-button{
  padding: 10px 10px;
  color: #fff;
  background: #142a4c;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media screen and (max-width: 1400px) {
  .relatorios-contander {
    max-height: 600px;
  }
  .relatorios-body {
    max-height: 500px;
  }
}
