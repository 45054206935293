.servicos-contander {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

.servicos-header {
  width: 100%;
  max-height: 10%;
  margin-top: 20px;
}

.servicos-header h1 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #142a4c;
  text-align: center;
  margin: 0;
  padding: 0;
}

.servicos-body {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.servicos-button {
  margin: 2vh 15px;
  border: none;
  color: #142a4c;
  font-size: 1.2rem;
  font-weight: 600;
  background-color: transparent;
}

.servicos-button:hover {
  color: #9ac31c;
  cursor: pointer;
}

.servico-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.servico-form-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
  justify-content: center;
  gap: 10px;
}

.servico-form-item label {
  width: 20%;
  font-size: 1rem;
  font-weight: 600;
  color: #142a4c;
  text-align: center;
}

.servico-form-item input {
  width: 30%;
  height: 30px;
  border: 1px solid #142a4c;
  border-radius: 5px;
  padding: 0 10px;
  font-size: 1rem;
  font-weight: 600;
  color: #142a4c;
}

.servico-form-button {
  width: 20%;
  height: 30px;
  border: none;
  border-radius: 5px;
  background-color: #142a4c;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  margin: 20px 0;
}

.servico-form-button:hover {
  color: #9ac31c;
  cursor: pointer;
}

.servicos-modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.servicos-modal-body {
  width: 50%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 20px;
  align-items: center;
  background: #fff;
  border-radius: 5px;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  overflow-x: hidden;
}

.servicos-modal-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.servicos-modal-header h1 {
  font-size: 18px;
  font-weight: 600;
  color: #142a4c;
  margin: 0;
}

.servicos-modal-footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 0 20px;
}

.servicos-modal-button {
  width: 25%;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background: #142a4c;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
  margin-top: 20px;
}

.servicos-modal-button:hover {
  transform: scale(1.05);
}

@media screen and (max-width: 1400px) {
  .servicos-contander {
    max-height: 600px;
  }
  .servicos-body {
    max-height: 500px;
  }
}
