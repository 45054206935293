.faturador-contander {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  max-height: 800px;
}

.faturador-header {
  width: 100%;
  max-height: 10%;
}

.faturador-body {
  width: 90%;
  display: flex;
  flex-direction: column;
  max-height: 600px;
}

.faturador-button {
  margin: 2vh 15px;
  border: none;
  color: #142a4c;
  font-size: 1rem;
  font-weight: 600;
  background-color: transparent;
}

.faturador-button:hover {
  color: #9ac31c;
  cursor: pointer;
}

@media screen and (max-width: 1400px) {
  .faturador-contander {
    max-height: 600px;
  }
  .faturador-body {
    max-height: 500px;
  }
}

.filters-container {
  display: flex;
  gap: 10px; /* Adiciona um espaçamento entre os selects */
  margin-bottom: 20px; /* Adiciona um espaçamento inferior entre os selects e o restante do conteúdo */
}

.anexos-consulta-select {
  flex: 1; /* Faz com que os selects ocupem o mesmo espaço */
  padding: 8px; /* Adiciona um pouco de padding para os selects */
  border-radius: 4px; /* Deixa as bordas levemente arredondadas */
}

.info {
  width: 100%;
  display: flex;;
  flex-wrap: wrap; /* Permite que os itens quebrem para a próxima linha */
  justify-content: space-between; /* Garante que os itens se distribuam uniformemente */
  align-items: flex-start; /* Alinha os itens ao início do contêiner */
 
  padding: 10px;
  border-radius: 8px; /* Define os cantos arredondados */
  gap: 10px; /* Define o espaço entre os itens filhos */
}



.saperx-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1400px;
  gap: 10px;
}

.faturador-cadastro-button {
  height: 35px;
  width: 100px;
  padding: 10px 20px;
  color: #fff;
  margin-left: 1px;
  background: #142a4c;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
  font-size: 12px;
  white-space: nowrap; 
}

.faturador-cadastro-button:hover {
  color: #9ac31c;
  cursor: pointer;
}

.but-container {
  display: flex;
  width: 100%;
  margin: 10px 0;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
}

.file-input-wrapper {
  display: flex;
  align-items: center; /* Centraliza verticalmente */
  justify-content: center; /* Centraliza horizontalmente */
  gap: 10px;
}

.file-input-label {
  max-width: 80px;
  display: inline-block;
  margin: 0;
  background-color: transparent; /* Cor de fundo */
  color: #142a4c; /* Cor de fundo */;
  border: 2px solid #142a4c; /* Borda do botão */

  padding: 12px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  text-align: center;
  white-space: nowrap; 
}
.file-input-label:hover {
  color: #9ac31c;
}

.file-input {
  display: none; /* Esconde o input padrão */
}

.file-name {
  font-size: 14px;
  color: #142a4c; /* Mantém a cor do texto igual à cor do botão */
  border-bottom: 2px solid #142a4c; /* Linha embaixo do texto */
  padding: 10px 0; /* Espaçamento vertical (sem o padding lateral) */
  text-align: center;
}

.myCheckContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.checkLabel {
  margin-left: 10px;
  cursor: pointer;
}