.login-wrapper{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.login-container{
    width: 30%;
    height: 60%;
    max-width: 400px;
    max-height: 600px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px #142a4c46;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:15px;
}

.login-header{
    width: 90%;
    height: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo{
    width: 320px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-form{
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
}

.login-input{
    flex: 1; 
    width: 100%;
    height: 40px;
    border-bottom: 2px solid #ccc;
    border-top:none;
    border-left:none;
    border-right:none;
    padding: 0 10px;
}

.login-input-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;

}

.login-icon-container {
    display: flex;
    align-items: center;
}

.login-icon {
    margin-left: -20px;
    color: #ccc;
}

.login-input:focus, .login-input:active, .login-input:hover{
    outline: none;
    border-bottom: 2px solid #9ac31c;
    border-top:none;
    border-left:none;
    border-right:none;
}

.login-button{
    width: 70%;
    height: 40px;
    padding: 0 10px;
    margin-top:10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #142a4c;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
}

.login-button:hover{
    background-color: #fff;
    color: #142a4c;
    border: 1px solid #9ac31c;
}

.login-links{
    width: 70%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom:20px
}

.login-esqueci{
    color: #142a4c;
    font-size: 14px;
    cursor: pointer;
    text-decoration: none;
}

.login-esqueci:hover{
    text-decoration: underline;
}

.login-text{
    color: #142a4c;
    font-size: 14px;
    margin:0;
    font-weight: bolder;
}

.div-esqueci{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 600px){
    .login-container{
        width: 100%;
        height: 100%;
    }

    .logo{
        width: 100px;
        height: 100%;
    }
}