.entrada-contander {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

.entrada-header {
  width: 100%;
  max-height: 10%;
}

.entrada-body {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.entrada-button,
.entrada-button input {
  margin: 2vh 15px;
  border: none;
  color: #142a4c;
  font-size: 1rem;
  font-weight: 600;
  background-color: transparent;
}

.entrada-limpar-button {
  margin: 2vh 15px;
  padding: 10px 20px;
  border: none;
  color: #fff;
  background-color: #142a4c;
  font-size: 1rem;
  font-weight: 600;
}

.entrada-button:hover,
.entrada-limpar-button:hover {
  color: #9ac31c;
  cursor: pointer;
}
.input-box {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: center;
  justify-content: center;
  margin-top: 20px;
}

.nota-info-box {
  margin-top: 20px;
}

.nota-info-header {
  margin-bottom: 20px;
  text-align: left;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;
  gap: 10px;
}

.nota-info-header p {
  font-size: 1.2rem;
  font-weight: 500;
  margin: 0;
}

@media screen and (max-width: 1400px) {
  .entrada-contander {
    max-height: 600px;
  }
  .entrada-body {
    max-height: 500px;
  }
}
