.client-container {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

.client-header {
  width: 100%;
  max-height: 10%;
}

.client-body {
  width: 97%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;

}

.client-button {
  margin: 2vh 15px;
  border: none;
  color: #142a4c;
  font-size: 1.2rem;
  font-weight: 600;
  background-color: transparent;
}

.client-button:hover {
  color: #9ac31c;
  cursor: pointer;
}

.cliente-consulta-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-collapse: collapse;
  width: 100%;
  margin: 20px auto;
}

.cliente-cadastro-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-collapse: collapse;
  width: 96%;
  margin: 20px auto;
  gap: 20px;
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
  max-height: 80vh;
  overflow-y: auto;
  box-sizing: border-box;
  margin: 0 auto;
}