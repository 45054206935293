.modal-edit {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.modal-edit-body {
  width: 60%;
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 20px;
  align-items: center;
  background: #fff;
  border-radius: 5px;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  overflow-x: hidden;
}

.edit-modal-content {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.edit-modal-content-item {
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 auto;
  flex-direction: column;
}

.edit-modal-content-item h2 {
  font-size: 16px;
  font-weight: 600;
  color: #111;
  margin: 0;
}

.edit-modal-content-item p {
  font-size: 14px;
  color: #333;
  margin: 5px;
  word-wrap: break-word;
}

.edit-modal-footer {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.edit-modal-button {
  width: 25%;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background: #142a4c;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
  margin-top: 20px;
}

.edit-modal-content-item label {
  font-size: 14px;
  color: #333;
  margin-left: 5px;
  font-weight: 600;
}
.edit-modal-content-item > select {
  width: 56%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 12px;
  color: #333;
  margin-left: 5px;
  font-weight: 600;
}

.edit-modal-content-item > input,
.edit-modal-content-item > textarea {
  width: 50%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 12px;
  color: #333;
  margin-left: 5px;
  font-weight: 600;
}

.edit-modal-button:hover {
  transform: scale(1.05);
}

.edit-button-box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
