.container-modal {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
}

.container-modal-body {
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-radius: 5px;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px;
  gap: 30px;
  background: #fff;
}
