.container-modal-itens {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 1000;
}

.container-modal-content-itens {
    display: flex;
    flex-direction: column;
    height: 92%;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 78%;
    padding: 20px 40px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
    overflow-y: auto;
    margin-left: 3%;

}

.container-modal-header-itens {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    padding-bottom: 0;
    border-bottom: 1px solid #ddd;
    background: #f5f5f5;
    border-bottom: 1px solid #ddd;
}

.container-modal-header-itens h1 {
    font-size: 1.5rem;
    color: #333;
    margin: 0;
}

.fechar-top-button {
    font-size: 1.5rem;
    color: #666;
    cursor: pointer;
    transition: color 0.2s ease;
}

.fechar-top-button:hover {
    color: #000;
}

.container-modal-body-itens {
    padding: 20px;
    overflow-y: auto;
    flex: 1;
}

.container-modal-body-itens table {
    width: 100%;
    border-collapse: collapse;
}

.container-modal-body-itens th,
.container-modal-body-itens td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
}

.container-modal-body-itens th {
    background: #f5f5f5;
    font-weight: bold;
}

.container-modal-body-itens tr:nth-child(even) {
    background: #f9f9f9;
}