.sidebar-container-normal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 2%;
  height: 100vh;
  background-color: #142a4c;
  border-right: 1px solid #9ac31c;
  padding: 20px;
  color: #fff;
  transition: all 0.4s ease-in-out;
}

.sidebar-container-active {
  display: flex;
  max-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 15%;
  height: 100vh;
  background-color: #142a4c;
  border-right: 1px solid #9ac31c;
  padding: 20px;
  color: #fff;
  transition: all 0.4s ease-in-out;
}

.sidebar-header-close {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #fff;
  font-size: 2rem;
}

.sidebar-header-open {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  color: #fff;
  position: relative;
}

.sidebar-header-open > img {
  width: 70%;
  height: 40px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}

.sidebar-header-open > svg {
  position: absolute;
  right: 0;
  cursor: pointer;
}

.sidebar-icon:hover {
  color: #9ac31c;
}

.sidebar-content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  margin-top: 30px;
  font-size: 1.2rem;
  width: 100%;
  height: 100%;
}

.sidebar-content > ul {
  list-style: none;
}

.sidebar-list {
  display: flex;
  align-items: flex-start;
  margin: 0 auto;
  padding: 0;
  flex-direction: column;
  gap: 10px;
  justify-content: flex-end;
  width: 100%;
  cursor: pointer;
  transition: all 0.8s ease-in-out;
}

.sidebar-item {
  display: flex;
  gap:5px;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-height: 50px;
  margin-bottom: 10px;
}


.sidebar-item > span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-left: 10px;
  font-size: medium;
}

/* .sidebar-content > ul > li:hover {
  color: #9ac31c;
} */

.sidebar-icon{
  cursor: pointer;
}


/* Estilos para sidebar-group */
.sidebar-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

/* Estilos para sidebar-item-group */
.sidebar-item-group {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
  margin-left: 15px;
}

.sidebar-item {
  display: flex;
  gap:5px;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-height: 50px;
  margin-bottom: 10px;
}

.sidebar-item > span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding-left: 10px;
  font-size: medium;
}

.sidebar-item:hover {
  color: #9ac31c;
}

.sidebar-subitem {
  display: flex;
  align-items: center;
  padding: 5px 0;
  margin: 5px 0;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.8rem; /* Define o tamanho da escrita como pequeno */
  padding-left: 10px; /* Adiciona espaço do lado esquerdo para o direito */
}


.sidebar-subitem > span {
  margin-left: 20px;
  color: #fff;
  font-size: 0.8rem; /* Define o tamanho da escrita como pequeno */
}


.li-item{
  width: 100%;
  display: flex;
  /* background-color: #d61010;  */
  align-items: center; /* Alinha os itens verticalmente no centro */
  justify-content: flex-start; /* Alinha os itens horizontalmente à esquerda */
  text-align: left; 
}


.selected {

  color: #9ac31c; /* Cor do item selecionado */
}
/* Estilos responsivos */
@media (max-width: 768px) {
  .sidebar-subitem {
    padding: 5px;
    font-size: 0.7rem; /* Ajusta o tamanho da escrita */
  }

  .sidebar-subitem > span {
    font-size: 0.7rem; /* Ajusta o tamanho da escrita */
  }
}

@media (max-height: 600px) {
  .sidebar-content {
    max-height: 475px;
    overflow-y: auto;
  }
  
  .sidebar-container-normal {
    width: 40px;
  }
}

@media (max-width: 480px) {
  .sidebar-subitem {
    padding: 3px;
    font-size: 0.6rem; /* Ajusta o tamanho da escrita */
  }

  .sidebar-subitem > span {
    font-size: 0.6rem; /* Ajusta o tamanho da escrita */
  }
}