.anexos-contander {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

.anexos-header {
  width: 100%;
  max-height: 10%;
}

.anexos-body {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.anexos-button {
  margin: 2vh 15px;
  border: none;
  color: #142a4c;
  font-size: 1.2rem;
  font-weight: 600;
  background-color: transparent;
}

.anexos-button:hover {
  color: #9ac31c;
  cursor: pointer;
}

.anexos-consulta-contander {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-collapse: collapse;
  width: 100%;
  margin: 0 auto;
}

.anexos-consulta-select {
  width: 100%;
  height: 40px;
  border: 1px solid #142a4c;
  border-radius: 5px;
  padding: 0 10px;
  font-size: 1rem;
  font-weight: 600;
  color: #142a4c;
  background-color: transparent;
  margin-bottom: 10px;
}

.anexos-consulta-select:hover,
.anexos-consulta-select:focus,
.anexos-consulta-select:active {
  border: 1px solid #9ac31c;
  cursor: pointer;
}

.anexos-consulta-select option {
  color: #142a4c;
  background-color: #fff;
  cursor: pointer;
}

.form-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-group {
  flex: 1 0 calc(40% - 16px);
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  margin: 0 auto;
}

.form-group label {
  width: 30%;
  font-size: 16px;
  font-weight: 600;
  color: #142a4c;
  margin-right: 5px;
}

.form-group input,
.form-group select {
  width: 80%;
  height: 30px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0 5px;
  font-size: 1rem;
  font-weight: 600;
  color: #142a4c;
  background-color: transparent;
  margin-bottom: 10px;
}

.form-group input:hover,
.form-group input:focus,
.form-group input:active,
.form-group select:hover,
.form-group select:focus,
.form-group select:active {
  border: 1px solid #9ac31c;
  cursor: pointer;
}
.form-linhas-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-container-linhas {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-top: 1px ridge #142a4c;
  padding: 5px 0;
  margin: 10px 5px;
}

.form-group-linhas {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.form-group-linhas label {
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  color: #142a4c;
  margin-right: 5px;
}

.form-group-linhas input,
.form-group-linhas select {
  width: 60%;
  height: 30px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0 5px;
  font-size: 1rem;
  font-weight: 600;
  color: #142a4c;
  background-color: transparent;
  margin-bottom: 10px;
}

.anexos-cadastro-button {
  width: 30%;
  margin: 20px 0;
  padding: 10px 10px;
  color: #fff;
  background: #142a4c;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
}

.anexos-cadastro-button:hover {
  color: #9ac31c;
  cursor: pointer;
}


@media (max-width: 576px) {
  .form-group {
    flex: 1 0 100%;
  }
}

@media screen and (max-width: 1400px) {
  .anexos-contander {
    max-height: 550px;
  }

}
