.patrimonio-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

.patrimonio-header {
  width: 100%;
  max-height: 10%;
  margin-top: 20px;
}

.patrimonio-header h1 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #142a4c;
  text-align: center;
  margin: 0;
  padding: 0;
}

.patrimonio-body {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.patrimonio-button {
  margin: 2vh 15px;
  border: none;
  color: #142a4c;
  font-size: 1rem;
  font-weight: 600;
  background-color: transparent;
}

.patrimonio-button:hover {
  color: #9ac31c;
  cursor: pointer;
}

.patrimonio-consulta-contander {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-collapse: collapse;
  width: 100%;
  margin: 0 auto;
}

.patrimonio-consulta-select {
  width: 100%;
  height: 40px;
  border: 1px solid #142a4c;
  border-radius: 5px;
  padding: 0 10px;
  font-size: 1rem;
  font-weight: 600;
  color: #142a4c;
  background-color: transparent;
}

.patrimonio-consulta-select:hover,
.patrimonio-consulta-select:focus,
.patrimonio-consulta-select:active {
  border: 1px solid #9ac31c;
  cursor: pointer;
}

.patrimonio-consulta-select option {
  color: #142a4c;
  background-color: #fff;
  cursor: pointer;
}
.marcas-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap:10px;
  margin: 10px 0;
}
.patrimonio-cadastro-button {
  width: 40%;
  margin: 5px 0;
  padding: 10px 10px;
  color: #fff;
  background: #142a4c;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
}

.patrimonio-cadastro-button:hover {
  color: #9ac31c;
  cursor: pointer;
}

.patrimonio-modal-footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.patrimonio-consulta-select-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.patrimonio-consulta-select-container label {
  width: 150px;
  font-size: 1rem;
  font-weight: 600;
  color: #142a4c;
}

.patrimonio-modal-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.patrimonio-modal-header h1 {
  font-size: 18px;
  font-weight: 600;
  color: #142a4c;
  margin: 0;
}

.patrimonio-modal-content-inputs {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap:10px;
}

.patrimonio-modal-content-inputs label {
  font-size: 1rem;
  font-weight: 600;
  color: #142a4c;
}


.patrimonios-icons {
  margin: 0 5px;
  color: #142a4c;
}

.patrimonios-icons:hover {
  color: #9ac31c;
  cursor: pointer;
}

.patrimonio-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.patrimonio-form-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
  justify-content: center;
  gap: 10px;
}

.patrimonio-form-item label {
  width: 20%;
  font-size: 1rem;
  font-weight: 600;
  color: #142a4c;
  text-align: center;
}

.patrimonio-form-item input {
  width: 67%;
  height: 30px;
  border: 1px solid #142a4c;
  border-radius: 5px;
  padding: 0 10px;
  font-size: 1rem;
  font-weight: 600;
  color: #142a4c;
}
.patrimonio-form-item select {
  width: 70%;
  height: 30px;
  border: 1px solid #142a4c;
  border-radius: 5px;
  padding: 0 10px;
  font-size: 1rem;
  font-weight: 600;
  color: #142a4c;
}

.patrimonio-form-item option {
  color: #142a4c;
  background-color: #fff;
  cursor: pointer;
}

.patrimonio-form-item input:hover,
.patrimonio-form-item input:focus,
.patrimonio-form-item input:active {
  border: 1px solid #9ac31c;
  cursor: pointer;
}

.patrimonio-form-item select:hover,
.patrimonio-form-item select:focus,
.patrimonio-form-item select:active {
  border: 1px solid #9ac31c;
  cursor: pointer;
}

.fechar-top-button {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  border: none;
  color: #142a4c;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.3s;
}
@media only screen and (max-width: 1280px) {
  .patrimonio-container{
    max-height: 550px;
    overflow-y: scroll;
  }
}
