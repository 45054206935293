.background-modal {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.765);
}

.alert-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 300px;
    background-color: white;
    border-radius: 5px;
    animation-name: maximize;
    animation-duration: 0.2s;
    animation-timing-function: linear;
    position: relative;
    min-height: 250px;
}

.alert-container h1 {
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 20px;
    color: #142a4c;
    padding: 10px;
}

.alert-content {
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: center;
    justify-content: center;
}

.alert-content-img {
    width: 100px;
    padding:5px;
}

.alert-title {
    text-align: center;
    color: rgb(64, 64, 64);
    padding:5px;
}

.alert-span {
    text-align: center;
    font-size: 15px;
    padding:2px;
    color: rgb(94, 94, 94);
}

.alert-container-button {
    display: flex;
    width: 100%;
}

.alert-success-ok-button {
    flex: 1;
    font-weight: lighter;
    font-size: 15px;
    color: white;
    background-color:#9ac31c;
    border: none;
    padding: 15px;
    transition: all 0.1s linear;
    cursor: pointer;
}

.alert-success-ok-button:hover {
    background-color: #142a4c;
    font-weight: 500;
}

.alert-warning-ok-button {
    flex: 1;
    font-weight: lighter;
    font-size: 15px;
    color: white;
    background-color:#e74e4e;
    border: none;
    padding: 15px;
    transition: all 0.1s linear;
    cursor: pointer;
}

.alert-warning-ok-button:hover {
    background-color: #be3030;
    font-weight: 500; 
}

.alert-icon{
    width: 30%;
    height: 30%;
    padding: 10px;
    color: #142a4c;
}

@keyframes maximize {
    from {
        opacity:0;
        transform: scale(0%);
    }
    to {
        opacity:1;
        transform: scale(100%);
    }
}

