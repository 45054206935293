.softwares-modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.softwares-modal-body {
  width: 75%;
  height: 85%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
  background: #f8f8f8;
  border-radius: 5px;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  overflow-x: hidden;
}

.softwares-modal-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.softwares-modal-header h1 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #142a4c;
  margin: 0;
}

.softwares-fechar-top-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 2rem;
  color: #142a4c;
  cursor: pointer;
}

.softwares-fechar-top-button:hover {
  color: red;
}
