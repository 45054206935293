.search-container {
  margin-bottom: 20px;
  position: relative;
}

.search-container input {
  width: 100%;
  height: 40px;
  border: 1px solid #142a4c;
  border-radius: 5px;
  padding: 0 10px;
  font-size: 1rem;
  font-weight: 600;
  color: #142a4c;
  background-color: transparent;
  margin-bottom: 10px;
}

/* .anexos-consulta-select {
  width: 100%;
  height: 40px;
  border: 1px solid #142a4c;
  border-radius: 5px;
  padding: 0 10px;
  font-size: 1rem;
  font-weight: 600;
  color: #142a4c;
  background-color: transparent;
  margin-bottom: 10px;
} */

.float-triangle{
position: absolute;
top: 2%;
left: 88%;
display: flex;
justify-content: center;
align-items: center;
}

.movimentacao-item{
  flex: 1;
  min-width: 40%;
  max-width: 48%;
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid #ccc;
  margin: 5px;
}

.client-moved {
  flex: 1;
  min-width: 40%;
  max-width: 48%;
  height: 100px;
  box-sizing: border-box;
  padding: 0 10px;
  border: 1px solid #ccc;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-decoration: none;
  text-overflow: ellipsis;
}

.client-moved-detail{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 5px;
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: #f9f9f9;
  position: relative;
  width: 29%;
}

.moved-clients-container {
  display: flex;
  justify-content: cen;
  align-items: center;
  gap: 3%;
  flex-wrap: wrap;
}

.search-container input:focus {
  outline: none;
  border-color: #9ac31c;
  box-shadow: 0 0 5px rgba(154, 195, 28, 0.5);
}

.main-table-container {
  width: 100%;
  max-height: 60vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

.data-table {
  border-collapse: collapse;
  width: 100%;
}

.data-table th,
.data-table td {
  padding: 8px;
  border-bottom: 1px solid #ddd;
  text-align: left;
  font-size: 1rem;
}

.data-table th {
  background-color: #142a4c;
  color: white;
}

.data-table tr:nth-child(even) {
  background-color: #ffffff;
}

.data-table tr:nth-child(odd) {
  background-color: #e1ecf7;
}

.data-table tr:hover {
  background-color: #f9f9f9;
}

.sort-table-button {
  background-color: transparent;
  border: none;
  color: #9ac31c;
  font-size: 1rem;
}

.pagination {
  margin: 5px auto 0 auto;
  text-align: center;
}

.pagination button {
  background-color: #f2f2f2;
  color: #142a4c;
  cursor: pointer;
  padding: 8px 12px;
  margin: 2px;
  border: none;
  border-radius: 3px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  transition-duration: 0.4s;
}

.pagination button:hover {
  background-color: #ddd;
  color: #9ac31c;
}

.pagination button:active {
  background-color: #bbb;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

.pagination button.active {
  background-color: #9ac31c;
  color: #fff;
}

.actions-buttons {
  color: #142a4c;
  font-size: 1.2rem;
  margin: 0 5px;
  transition-duration: 0.4s;
}

.actions-buttons:hover {
  transform: scale(1.2);
  cursor: pointer;
  color: #9ac31c;
}

.box-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  width: 100%;
}

.box-items span {
  font-size: 1rem;
  font-weight: 600;
  color: #142a4c;
}

.items-per-page {
  font-size: 1rem;
  font-weight: 600;
  color: #142a4c;
}

.items-per-page select {
  padding: 5px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  margin-left: 5px;
}

/* Responsivo para telas pequenas */
@media screen and (max-width: 600px) {
  .search-container {
    margin-bottom: 10px;
  }

  .data-table th,
  .data-table td {
    font-size: 14px;
    padding: 8px 10px;
  }
}