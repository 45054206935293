.container-table-transfer {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container-table-transfer-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 20px;
}
/* .container-table-transfer-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-height: 500px; 
  overflow-y: auto; 
  gap: 20px;
} */

.transfer-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.th-transfer-checkbox {
  width: 15%;
  border: 1px solid #ddd;
  background-color: #f2f2f2;
}

.container-button-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.th-title {
  border: 1px solid #ddd;
  padding: 8px;
  color: #142a4c;
  background-color: #f2f2f2;
}

.tr-td {
  border: 1px solid #ddd;
  padding: 8px;
  color: #142a4c;
}

.td-red {
  color: red;
  font-weight: bold;
  border: 1px solid #ddd;
  text-align: center;
}

.title-table-transfer {
  color: #142a4c;
}

.button-transfer {
  padding: 5px 10px;
  cursor: pointer;
  background-color: #142a4c;
  color: white;
  border: none;
  border-radius: 3px;
  align-items: center;
  justify-content: center;
}

.button-transfer:hover {
  background-color: #4caf50;
}

input[type="checkbox"] {
  cursor: pointer;
}
.pagination-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  gap: 10px;
}

.pagination-container button {

  color: #142a4c;
  border: none;
  border-radius: 3px;
  padding: 5px 10px;
  cursor: pointer;
}

.pagination-container button:hover {
  color: #4caf50;
}

.search-container {
  margin-top: 10px;
}

.search-container input {
  padding: 5px;
  width: 100%;
  box-sizing: border-box;
}

.contander-table-transfer{
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .container-table-transfer-row {
    flex-direction: column;
  }

  .th-title {
    width: 100%;
  }
}
