.overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #00000080;
    z-index: 1000;
}

.modal {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 50%;
    height: 80%;
    padding: 20px;
    position: relative;
    overflow-y: auto;
}

.header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    margin-left: 1%; 
    border-bottom: 1px solid #ddd;
}

.closeButton {
    font-size: 1.5rem;
    color: #666;
    background: none;
    border: none;
    cursor: pointer;
    transition: color 0.2s ease;
}

.closeButton:hover {
    color: #000;
}

.body {
    padding: 20px 0;
}

.body p {
    margin: 10px 0;
    font-size: 1rem;
    color: #333;
}

.body h3 {
    margin-top: 20px;
    font-size: 1.2rem;
    color: #444;
}

.body ul {
    list-style: none;
    padding: 0;
}

.body li {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: #f9f9f9;
}

.footer {
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
    border-top: 1px solid #ddd;
}

.button {
    padding: 10px 20px;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.button:hover {
    background-color: #0056b3;
}