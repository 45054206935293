.softwares-contander {
  width: 100%;
  height: 100%;
  max-height: 90vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

.softwares-header {
  width: 100%;
  max-height: 10%;
}

.softwares-body {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.softwares-button {
  margin: 2vh 15px;
  border: none;
  color: #142a4c;
  font-size: 1rem;
  font-weight: 600;
  background-color: transparent;
}

.softwares-button:hover {
  color: #9ac31c;
  cursor: pointer;
}
