.faturador-patrimonio-contander {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  max-height: 800px;
}

.faturador-patrimonio-header {
  width: 100%;
  max-height: 10%;
}

.faturador-patrimonio-body {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 600px;
}

.faturador-patrimonio-button {
  margin: 2vh 15px;
  border: none;
  color: #142a4c;
  font-size: 1rem;
  font-weight: 600;
  background-color: transparent;
}

.faturador-patrimonio-button:hover {
  color: #9ac31c;
  cursor: pointer;
}

@media screen and (max-width: 1400px) {
  .faturador-patrimonio-contander {
    max-height: 600px;
  }
  .faturador-patrimonio-body {
    max-height: 500px;
  }
}

.filters-container {
  display: flex;
  gap: 10px; /* Adiciona um espaçamento entre os selects */
  margin-bottom: 20px; /* Adiciona um espaçamento inferior entre os selects e o restante do conteúdo */
}

.anexos-consulta-select {
  max-height: 40px;
  flex: 1; /* Faz com que os selects ocupem o mesmo espaço */
  padding: 8px; /* Adiciona um pouco de padding para os selects */
  border-radius: 4px; /* Deixa as bordas levemente arredondadas */
}

.info-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1.5px solid #142a4c; /* Define a cor e a espessura da borda */
  border-radius: 8px; /* Define os cantos arredondados */
}

.info-container span {
  color: rgb(1, 1, 2);
  margin-right: 20px;
}