.dash-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 96%;
  height: 100%;
  padding: 20px;
  color: #142a4c;
  transition: all 0.5s ease-in-out;
  overflow-y: auto;
  overflow-x: hidden;
}

.dash-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  align-items: center;
  padding: 20px;
}

.dash-card {
  background-color: #fff;
  box-shadow: 0px 4px 8px #142a4c56;
  padding: 16px;
  margin: 16px;
  text-align: center;
  border-left: 2px solid #9ac31c;
}

.dash-card h3 {
  color: #142a4c;
  font-size: 1.5rem;
  margin-bottom: 8px;
}

.dash-card p {
  color: #142a4c;
  font-size: 1.2rem;
  margin-bottom: 4px;
}

@media screen and (max-width: 1400px) {
  .dash-container {
    max-height: 500px;
  }
}
