.home-container{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
}

.home-content{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
}

