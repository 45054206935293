/* Estilização do contêiner principal */
.open-table-container {
  margin: 20px;
  font-family: 'Arial', sans-serif;
  max-height: 90%;
  overflow-y: auto;
}

/* Estilo do cabeçalho da tabela */
.open-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.open-table th {
  background-color: #142a4c;
  color: white;
  padding: 12px;
  text-align: center;
  font-size: 1rem;
  border-bottom: 2px solid #142a4c; 
  cursor: pointer;
}


.open-table th::after {
  content: '';
  margin-left: 8px;
  font-size: 0.8rem;
  color: #666;
}

/* Estilo das células da tabela */
.open-table td {
  padding: 12px;
  border-bottom: 1px solid #ddd; /* Linha divisória sutil */
}

/* Estilo para linhas alternadas */

/* Estilo ao passar o mouse */
.open-table tbody tr:hover {
  background-color: #e2e2e2; /* Cinza mais escuro */
  cursor: pointer;
}

/* Botões de ordenação */
.sort-button {
  padding: 8px 16px;
  margin-right: 10px;
  background-color: #142a4c; /* Verde padrão */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.sort-button:hover {
  background-color: #9ac31c; /* Verde mais escuro ao passar o mouse */
}

/* Estilo do título */
.open-table-title {
  font-size: 2rem;
  color: #333;
}

.open-table-info {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
}
