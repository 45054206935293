.fornecedores-modal {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }
  
  .fornecedores-modal-body {
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap:20px;
    align-items: center;
    background: #fff;
    border-radius: 5px;
    position: relative;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    overflow-x: hidden;
  }
  
  .fornecedores-modal-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .fornecedores-modal-header h1 {
    font-size: 18px;
    font-weight: 600;
    color: #142a4c;
    margin: 0;
  }
  
  .fornecedores-modal-content {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
  
  .fornecedores-modal-content-item {
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 auto;
    flex-direction: column;
  }

  .fornecedores-edit-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom:20px;
  }

  .fornecedores-modal-content-item label {
    font-size: 14px;
    color: #333;
    margin-left: 5px;
    font-weight: 600;
  }
  .fornecedores-modal-content-item > select{
    width: 56%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 12px;
    color: #333;
    margin-left: 5px;
    font-weight: 600;
  }
  
  .fornecedores-modal-content-item > input,
  .fornecedores-modal-content-item  > textarea
   {
    width: 50%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 12px;
    color: #333;
    margin-left: 5px;
    font-weight: 600;
  }

  .fornecedores-modal-content-item h2 {
    text-align: justify;
    font-size: 16px;
    font-weight: 600;
    color: #111;
    margin: 0;
  }
  
  .fornecedores-modal-content-item p {
    font-size: 14px;
    color: #333;
    margin-left: 5px;
  }
  
  
  .button-container {
    width: 100%;
  }

  @media screen and (max-width: 1400px) {
    .fornecedores-modal-body {
      width: 60%;
      height: 80%;
      padding: 30px;
    }
  }
  
  @media screen and (min-width: 1400px) {
    .fornecedores-modal-body {
      width: 60%;
      height: 50%;
      overflow-x: hidden;
    }
  }
  