.linhas-contander {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

.linhas-header {
  width: 100%;
  max-height: 10%;
}

.linhas-body {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.linhas-button {
  margin: 2vh 15px;
  border: none;
  color: #142a4c;
  font-size: 1rem;
  font-weight: 600;
  background-color: transparent;
}

.linhas-button:hover {
  color: #9ac31c;
  cursor: pointer;
}

@media screen and (max-width: 1400px) {
  .linhas-contander {
    max-height: 600px;
  }
  .linhas-body {
    max-height: 500px;
  }
}
