.frameSelect-box {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
}

.frameSelect-container {
    width: 45%;
    max-height: 350px;
    border: 1.5px solid #142a4c;
    border-radius: 8px;
    background-color: #f5f5f5;
    transition: ease-in-out 0.3s;
}

.frameSelect-container-header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    color: #142a4c;;
}

.frameSelect-container-body {
    max-height: 280px;
    overflow-y: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 10px;
    align-items: center;
    padding-left: 16px;
}

.remove-button {
    cursor: pointer;
    color: white;
    background-color: #AF1616;
    border: none;
    border-radius: 4px;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    padding: 0;
}
  
.remove-button:hover {
    background-color: darkred;
}

.remover-setores-anexos {
    cursor: pointer;
}

.remover-setores-anexos:hover {
    color: darkred;
}