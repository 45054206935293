.fornecedores-contander {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

.fornecedores-header {
  width: 100%;
  max-height: 10%;
  margin-top: 20px;
}

.fornecedores-header h1 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #142a4c;
  text-align: center;
  margin: 0;
  padding: 0;
}

.fornecedores-body {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fornecedores-button {
  margin: 2vh 15px;
  border: none;
  color: #142a4c;
  font-size: 1.2rem;
  font-weight: 600;
  background-color: transparent;
}

.fornecedores-button:hover {
  color: #9ac31c;
  cursor: pointer;
}

.fornecedores-consulta-contander {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-collapse: collapse;
  width: 100%;
  margin: 0 auto;
}

@media screen and (max-width: 1400px) {
  .fornecedores-contander {
    max-height: 600px;
  }
  .fornecedores-body {
    max-height: 500px;
  }
}
